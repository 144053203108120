'use client'

import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'

// Configurations
import LocaleConfig from '@/config/locale'

// Components
const NotFoundPage = dynamic(() => import('@/components/pages/not-found'))

const Page = () => {
	// Variables
	const pathname = usePathname()
	const lng = pathname.split('/').find(item => item) ?? LocaleConfig.default

	return <NotFoundPage lng={lng} />
}

export default Page
